import chat from './chat.json';
import Day from './components/Day';
import {useState, useEffect} from 'react';

function App() {

  const [masterDate, setMasterDate] = useState('25.12.2001');
  
  const renderedChunks = chat.map(chunk => {
    return <Day key={chunk.date} date={chunk.date} messages={chunk.messages} />;
  });
  
  window.addEventListener('scroll', () => {
    const masterDateY = document.querySelector('.master-date').getBoundingClientRect().y;
    const allDates = Array.from(document.querySelectorAll('.date'));
    const passedDates = allDates.filter(date => {
      return date.getBoundingClientRect().y <= masterDateY;
    });
    if (passedDates.length >= 1) {
      setMasterDate(passedDates[passedDates.length - 1].querySelector('span').textContent);
    }
  });

  useEffect(() => {
    window.scroll({
      top: document.body.scrollHeight
    });
    
    const button = document.querySelector('.master-date');
    button.addEventListener('click', () => {
      console.log(window.find('danke'));
    });
  }, []);

  const randomMessage = () => {
    window.scroll({
      top: Math.floor(Math.random() * document.body.scrollHeight),
      behavior: "smooth",
    })
  }
  

  return (<>
    <img className="background" src="bg.jpeg" alt="background" />
    <div className="chat">
      <p className="master-date">
        <span>{masterDate}</span>
      </p>
      {renderedChunks}
    </div>
    <button className="search-button" onClick={randomMessage}>rändm nochricht</button>
  </>);
}

export default App;

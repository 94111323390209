export default function Message({date, time, author, content, first = false}) {

  const direction = author === 'Jenni' ? 'out' : 'in';
  const firstMsg = first ? 'first' : '';

  const renderedTime = () => {
    const splitted = time.split(':');
    return splitted[0] + ':' + splitted[1];
  }

  const dangerousInnerHTML = text => {
    text = text.replace('<', '&lt;').replace('>', '&gt;');

    let returnValue = '';
    const splitContent = text.split('*');
    const unevenAsterisks = splitContent.length % 2 === 0;
    splitContent.forEach((c, i) => {
      if (unevenAsterisks && i === splitContent.length - 1) {
        returnValue += '*' + c;
        return
      }
      if (i % 2 === 1) {
        returnValue += '<b>' +  c + '</b>';
        return;
      }
      returnValue += c;
    });
    return returnValue;
  }

  return (<div className={`${direction} ${firstMsg} message__wrap`}>
    <div className="message">
      <div className="message__text" dangerouslySetInnerHTML={{__html: dangerousInnerHTML(content)}}></div>
      <div className="message__time">{renderedTime()}</div>
    </div>
  </div>);
}


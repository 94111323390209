import Message from './Message';

export default function Day({date, messages}) {



  const renderedMessages = messages.map((message, index) => {
    return (
      <Message
        key={index}
        date={message.date}
        time={message.time}
        author={message.author}
        content={message.content}
        first={index === 0 || messages[index -  1].author !== message.author}
      />
    );
  });
  return (<>
    <p className="date">
      <span>{date}</span>
    </p>
    {renderedMessages}
  </>);
}